import React, { useState } from 'react'
import { Col, Row } from 'reactstrap'


const Artists = () => {

    const [activeMac, setIsActiveMac] = useState(false)
    const [activeRetna, setIsActiveRetna] = useState(false)


    return (
        <>
            <section className='border-bottom' id="artists">
                <Row className='d-flex align-items-center justify-content-center'>
                    <Col lg="6">
                        <h2 className='ff-bold text-gray text-uppercase subheading mb-lg-0'>ARTISTS</h2>
                    </Col>
                    <Col lg="6">
                        <p className='ff-regular text-gray text-uppercase'>EL MAC and RETNA painted the Memento Mori mural in 2009. EL MAC painted the figure and RETNA painted the background designs.</p>
                    </Col>
                </Row>
            </section>
            <section className='border-bottom'>
                <Row>
                    <Col lg="5">
                        <div className='d-flex mb-4' data-aos="fade-right"
                            data-aos-offset="300"
                            data-aos-easing="ease-in-sine">
                            <div className='d-flex flex-column' >
                                <img src={require('../../assets/img/Landing_Page/mac1.png').default} className="img-fluid" />
                            </div>
                            <div className='d-flex flex-column ml-3'>
                                <img src={require('../../assets/img/Landing_Page/mac2.png').default} className="img-fluid" />
                                <img src={require('../../assets/img/Landing_Page/mac3.png').default} className="img-fluid mt-2 mt-sm-3" />
                            </div>
                        </div>

                        <h1 className='text-uppercase ff-bold text-gray'>EL MAC</h1>

                        <div className="block-item-text">
                            <input type="checkbox" hidden className="read-more-state" id="expanded-2" />
                            <div class="read-more-wrap">

                                <p className='ff-regular text-gray text-uppercase'>EL MAC, born Miles MacGregor, is an internationally renowned artist born and based in Los Angeles. He began painting both smaller indoor works as well as public murals and graffiti <span className={`${activeMac ? 'd-none' : 'd-inline'}`}>...</span>

                                    <span className="read-more-target">
                                        in Phoenix in the mid 1990s, and since that time has developed his unique visual aesthetic and rendering style which utilizes repeating contour patterns. His work draws on influences from classical European art, social realism, symbolism and devotional art, as well as the Chicaco and Mexican culture he grew up around. He is best known for his meticulous paintings and large-scale murals exploring feminine beauty and honoring ordinary, overlooked, or marginalized people. He has been commissioned to paint all over the world, for museums, universities and other cultural institutions, including the Groeninge Museum, San José Museum of Art, Northeastern University, University of California, QAGoMA, Fondazione PRADA, and the Mexican secretariat of Foreign Affairs, as well as murals in Belgium, Cambodia, Cuba, Denmark, England, Germany, Ireland, Morocco, Puerto Rico, Singapore, South Korea, Spain, Sweden, and Vietnam.
                                    </span>
                                </p>
                            </div>
                            <label for="expanded-2" className="read-more-trigger_closed read-more-btn px-5 py-2 text-dark cursor-pointer mt-4" onClick={() => setIsActiveMac(!activeMac)}>READ MORE</label>
                            <label for="expanded-2" className="read-more-trigger_opened cursor-pointer" onClick={() => setIsActiveMac(!activeMac)}>READ LESS</label>
                        </div>


                        <div className='mt-5 pt-lg-5'>
                            <h1 className='text-uppercase ff-bold text-gray'>follow el mac</h1>
                            <div className='d-flex'>
                                <a href="https://elmac.net/" target='_blank'> <button className='follow-btn p-2 mr-2'>WEBSITE</button></a>
                                <a href="https://www.instagram.com/mac_arte/" target='_blank'><button className='follow-btn p-2 mr-2'>INSTAGRAM</button></a>
                                <a href="https://www.facebook.com/ELMACS/" target='_blank'><button className='follow-btn p-2'>FACEBOOK</button></a>
                            </div>
                        </div>
                    </Col>
                    <Col lg="2"></Col>
                    <Col lg="5" className='mt-5 mt-lg-0'>
                        <div className='d-flex mb-4' data-aos="fade-right"
                            data-aos-offset="300"
                            data-aos-easing="ease-in-sine">
                            <div className='d-flex flex-column'>
                                <img src={require('../../assets/img/Landing_Page/retina1.png').default} className="img-fluid" />
                            </div>
                            <div className='d-flex flex-column ml-3'>
                                <img src={require('../../assets/img/Landing_Page/retina2.png').default} className="img-fluid" />
                                <img src={require('../../assets/img/Landing_Page/retina3.png').default} className="img-fluid mt-2 mt-sm-3" />
                            </div>
                        </div>
                        <div>
                            <h1 className='text-uppercase ff-bold text-gray'>RETNA</h1>
                            <div className="block-item-text">
                                <input type="checkbox" hidden className="read-more-state" id="expanded-3" />
                                <div class="read-more-wrap d-flex flex-lg-row">

                                    <p className='ff-regular text-gray text-uppercase '>RETNA, born Marquis Lewis, is known for large-scale paintings covered in long, geometric scripts that evoke a secret language. The artist’s shapes take inspiration from  <span className={`${activeRetna ? 'd-none' : 'd-inline'}`}>...</span>
                                        <span className="read-more-target">
                                            Egyptian hieroglyphics, Arabic and Hebrew calligraphy, Gothic blackletter, and graffiti, among other global influences. RETNA got his start as a graffiti artist while growing up in Los Angeles. He has since exhibited in New York, Los Angeles, and London, among other cities. His work regularly sells for five figures on the secondary market. RETNA has collaborated with brands including Louis Vuitton, Nike, and Chanel; created an album cover for Justin Bieber; and designed sets for a production of Aida at the Washington National Opera and San Francisco Opera.
                                        </span>
                                    </p>
                                </div>
                                <label for="expanded-3" className="read-more-trigger_closed read-more-btn px-5 py-2 text-dark cursor-pointer mt-4" onClick={() => setIsActiveRetna(!activeRetna)}>READ MORE </label>
                                <label for="expanded-3" className="read-more-trigger_opened cursor-pointer" onClick={() => setIsActiveRetna(!activeRetna)}>READ LESS</label>
                            </div>
                        </div>
                        <div className='mt-5 pt-lg-5'>
                            <h1 className='text-uppercase ff-bold text-gray'>follow retna</h1>
                            <div className='d-flex'>
                                <a href=" https://www.artsy.net/artist/retna" target='_blank'><button className='follow-btn p-2 mr-2'>WEBSITE</button></a>
                                <a href=" https://www.instagram.com/retna_art/" target='_blank'><button className='follow-btn p-2 mr-2'>INSTAGRAM</button></a>

                            </div>
                        </div>
                    </Col>
                </Row>
            </section>
        </>
    )
}

export default Artists