import React from 'react'
import { Col, Row } from 'reactstrap'

const Mural = () => {
    return (
        <>
            <section className='border-bottom' id="mural">
                <Row>
                    <Col lg="6" data-aos="flip-up">
                        <h2 className='ff-bold text-gray text-uppercase subheading'>Mural</h2>
                        <p className='ff-regular text-gray text-uppercase mb-4'>Memento Mori is a floor-to-ceiling mural painted by world-renowned graffiti artists EL MAC &amp; RETNA.</p>
                        <p className='ff-regular text-gray text-uppercase mb-4'>The Mural lives in a penthouse recording studio located on Hollywood &amp; Vine in Los Angeles,California. Walk right outside and see the stars of Hollywood Boulevard.</p>
                        <p className='ff-regular text-gray text-uppercase mb-4'>This recording studio is a historical landmark and has been visited by the likes of Drake, Cardi B, and Kanye.</p>
                    </Col>
                    <Col lg="6" data-aos="fade-up"
                        data-aos-duration="3000">
                        <img src={require('../../assets/img/Landing_Page/group-image.png').default} className="img-fluid" />
                    </Col>
                </Row>
            </section>
        </>
    )
}

export default Mural