/*!

=========================================================
* exceed - v1.0.0
=========================================================
* Coded by Ecrubit
*/
import React from "react";
// reactstrap components
import {
    Row,
    Col
} from "reactstrap";

export default function Footer() {

    const ScrollTo = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        })
    }
    return (
        <div className="bg-footer">
            <section className="py-5" data-aos="zoom-in-up">
                <div className="mt-5 border-bottom-style">
                    <Row className="pb-3">
                        <Col xl="6" lg="6" md="12" sm="12" xs="12" className="mt-3 mt-lg-0 d-flex flex-column align-items-center align-items-lg-start justify-content-center justify-content-lg-start">
                            <h1 className="ff-bold text-uppercase text-gray">Memento Mori</h1>
                            <div className="nav-link-space footer-text d-flex flex-column flex-sm-row align-items-center justify-content-start text-primary">
                                <a href="/#mural" className="text-gray ff-regular font-para pr-sm-3">MURAL</a>
                                <a href="/#nft" className="text-gray ff-regular font-para px-sm-3">NFTS</a>
                                <a href="/#benefits" className="text-gray ff-regular font-para px-sm-3">BENEFITS</a>
                                <a href="/#symba" className="text-gray ff-regular font-para px-sm-3">SYMBA</a>
                                <a href="/#artists" className="text-gray ff-regular font-para px-sm-3">ARTISTS</a>
                                <a href="/#faq" className="text-gray ff-regular font-para px-sm-3">FAQ</a>
                            </div>
                        </Col>
                        <Col xl="6" lg="6" md="12" sm="12" xs="12" className="mt-5 mt-lg-0 d-flex flex-column flex-lg-row justify-content-center justify-content-lg-end align-items-center align-items-lg-end">
                            <div className="mr-lg-5 text-center text-lg-left">
                                <h3 className="ff-bold text-gray mb-3">Follow Us</h3>
                                <h4 className="ff-regular text-gray mb-2"><a href="http://www.twitter.com" target={'_blank'} className="text-gray">TWITTER</a></h4>
                                <h4 className="ff-regular text-gray mb-0" ><a href="http://www.discord.com" target={'_blank'} className="text-gray">DISCORD</a></h4>
                            </div>
                            <div className="mt-4 mt-lg-0 cursor-pointer" onClick={ScrollTo}>
                                <img src={require('../../assets/img/Landing_Page/back-to-top.png').default} className="img-fluid" />
                                <div className="ff-regular text-gray">BACK TO TOP</div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="ff-regular text-uppercase text-center text-gray pt-5">© Copyright  |  Memento Mori 2023.</div>
            </section>
        </div>
    );
}
