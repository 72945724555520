import React from 'react'
import { Col, Row } from 'reactstrap'

const Mural = () => {
    return (
        <>
            <section className='border-bottom' id="nft">
                <Row>
                    <Col lg="6">
                        <h2 className='ff-bold text-gray text-uppercase subheading' data-aos="flip-up">NFTS</h2>
                    </Col>
                    <Col lg="6" data-aos="flip-up">
                        <p className='ff-regular text-gray text-uppercase mb-4 '>In an effort to preserve EL MAC and RETNA’s incredible work, we are fully digitizing the mural. We believe everyone should get a chance to own a piece of this mural, and in essence, the studio.</p>
                        <p className='ff-regular text-gray text-uppercase mb-4 '>We are dividing the digital art piece into 2,000 unique sections. With this original take on “fractionalization”, each person gets a 1/1 piece of the mural. These pieces will be sold as digital assets or NFTs and the piece you receive will be fully random. You may get a piece of the nose, a piece of a rose, or a piece of EL MAC’s signature.</p>
                    </Col>
                </Row>
            </section>
        </>
    )
}

export default Mural