import React, { useState } from 'react'
import { Col, Row } from 'reactstrap'

const Symba = () => {
    const [active, setIsActive] = useState(false)

    return (
        <>
            <section className='border-bottom' id="symba">
                <Row className='d-flex align-items-center justify-content-center'>
                    <Col lg="6">
                        <h2 className='ff-bold text-gray text-uppercase subheading mb-lg-0'>SYMBA</h2>
                    </Col>
                    <Col lg="6">
                        <p className='ff-regular text-gray text-uppercase'>As a regular at the recording studio where the Memento Mori mural lives, popular recording artist SYMBA will be the face of the Mementi Mori brand</p>
                    </Col>
                </Row>
            </section>
            <section className='border-bottom'>
                <Row>
                    <Col lg="6" className='d-flex flex-column justify-content-between order-2 order-lg-1 mt-5 mt-lg-0'>


                        <div className="block-item-text">
                            <input type="checkbox" hidden className="read-more-state" id="expanded-1" />
                            <div class="read-more-wrap" >

                                <p className='ff-regular text-gray text-uppercase read-text'>Bay Area-born and Los-Angeles based rapper Symba dove headfirst
                                    into the rap game following a rough upbringing. After a series of
                                    collaborations with Young Curt took off in the Bay
                                    <span className={`${active ? 'd-none' : 'd-inline'}`}>...</span>

                                    <span className="read-more-target ">
                                        , he headlined early Los Angeles shows with YG, Tyga, and DJ Mustard as early as 2010. Upon arriving in the City of Angels, he turned heads with the 2 More Words mixtape in 2015, boasting collaborations with the likes of Marc E. Bassy and IamSu!. In addition to praise from XXL, Hypebeast dubbed it “a fiery collection of songs balancing club-ready hooks with deft, incisive narratives.” His grind paid off, and he inked a short-lived deal with Columbia Records in 2017. Early demos caught the attention of Atlantic Records who signed him during 2019. He most recently collaborated with ESPN on creating "Outrageous", the soundtrack the NBA will use for their Play-In Tournament.
                                    </span>
                                </p>
                            </div>
                            <label for="expanded-1" className="read-more-trigger_closed read-more-btn px-5 py-2 text-dark cursor-pointer mt-4" onClick={() => setIsActive(!active)}>
                                READ MORE
                            </label>
                            <label for="expanded-1" className="read-more-trigger_opened cursor-pointer" onClick={() => setIsActive(!active)} >READ LESS</label>
                        </div>


                        <div className='mt-5 mt-lg-0'>
                            <h1 className='text-uppercase ff-bold text-gray'>Follow Symba</h1>
                            <div className='d-flex'>
                                <a href="https://www.officialsymba.com/" target='_blank'><button className='follow-btn p-2 mr-2'>WEBSITE</button></a>
                                <a href="https://www.instagram.com/therealsymba/" target='_blank'><button className='follow-btn p-2 mr-2'>INSTAGRAM</button></a>
                                <a href=" https://twitter.com/TheRealSymba" target='_blank'><button className='follow-btn p-2'>TWITTER</button></a>
                            </div>
                        </div>
                    </Col>
                    <Col lg="6" className='d-flex order-1 order-lg-2'>
                        <div className='d-flex flex-column' data-aos="fade-up"
                            data-aos-anchor-placement="top-bottom">
                            <img src={require('../../assets/img/Landing_Page/symba1.png').default} className="img-fluid" />
                            <img src={require('../../assets/img/Landing_Page/symba2.png').default} className="img-fluid mt-3 mt-sm-4" />
                        </div>
                        <div className='d-flex flex-column ml-3'>
                            <img src={require('../../assets/img/Landing_Page/symba3.png').default} className="img-fluid" />
                            <img src={require('../../assets/img/Landing_Page/symba4.png').default} className="img-fluid mt-1" />
                        </div>
                    </Col>
                </Row >
            </section >
        </>
    )
}

export default Symba