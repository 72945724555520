import React from 'react'
import { Row, Col, Card, CardImg } from 'reactstrap'
import img1 from "../../assets/img/Landing_Page/1.png"
import img2 from "../../assets/img/Landing_Page/2.png"
import img3 from "../../assets/img/Landing_Page/3.png"
import img4 from "../../assets/img/Landing_Page/4.png"
import img5 from "../../assets/img/Landing_Page/5.png"
import img6 from "../../assets/img/Landing_Page/6.png"
import Carousel from 'react-elastic-carousel';

const Slide = () => {

    const slides = [img3, img1, img5, img4, img2, img6]

    const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 300, itemsToShow: 2 },
        { width: 550, itemsToShow: 2 },
        { width: 768, itemsToShow: 3 },
        { width: 992, itemsToShow: 4 }
    ]
    return (
        <section className='border-bottom'>
            <Row data-aos="fade-up"
                data-aos-anchor-placement="top-bottom">
                <Carousel breakPoints={breakPoints} >
                    {slides.map((slide, index) => {
                        return (

                            <Card className='mr-3'>
                                <CardImg src={slide} width={220} height={270} className="shakeanimation"></CardImg>
                            </Card>)
                    })}
                </Carousel>
            </Row>
        </section>
    )
}

export default Slide