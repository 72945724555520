import React, { useState } from 'react'
import { Row, Col } from 'reactstrap'

const FAQ = () => {
    const [active, setIsActive] = useState(null)

    const toggle = (i) => {
        if (active === i) {
            return setIsActive(null)
        }
        setIsActive(i)
    }
    return (
        <section className='px-0' id="faq">
            <div className='bg-faq text-center'>
                <h1 className='ff-bold text-white text-uppercase p-3'>frequently asked questions</h1>
            </div>
            <section>
                <div className='d-flex flex-column flex-md-row w-100'>
                    <div className='w-100'>
                        <div className='border-faq p-3 cursor-pointer' onClick={() => toggle(1)}>
                            <Row className='d-flex align-items-center justify-content-between'>
                                <Col xs="2">
                                    <h2 className='text-gray ff-bold mb-0'>01</h2>
                                </Col>
                                <Col xs="8" className='question-div d-flex align-items-center'>
                                    <h3 className='text-gray ff-bold mb-0 text-uppercase'>What is an NFT?</h3>
                                </Col>
                                <Col xs="2" className='text-right'>
                                    {active === 1 ? <img src={require('../../assets/img/Landing_Page/minus.png').default} width={50} className="img-fluid" /> : <img src={require('../../assets/img/Landing_Page/plus.png').default} width={50} className="img-fluid" />}
                                </Col>
                            </Row>
                            <div className={`${active === 1 ? 'content show mt-3 text-uppercase text-gray ff-regular ' : 'content text-uppercase text-gray ff-regular '}`}>
                                An NFT, or non-fungible token, is a one-of-a-kind digital item that gives users the ability to prove ownership of goods.
                            </div>
                        </div>
                    </div>
                    <div className='w-100'>
                        <div className='border-faq p-3 cursor-pointer' onClick={() => toggle(2)}>
                            <Row className='d-flex align-items-center justify-content-between '>
                                <Col xs="2">
                                    <h2 className='text-gray ff-bold mb-0'>02</h2>
                                </Col>
                                <Col xs="8" className='question-div d-flex align-items-center'>
                                    <h3 className='text-gray ff-bold mb-0 text-uppercase'>How do I buy an NFT?</h3>
                                </Col>
                                <Col xs="2" className='text-right'>
                                    {active === 2 ? <img src={require('../../assets/img/Landing_Page/minus.png').default} width={50} className="img-fluid" /> : <img src={require('../../assets/img/Landing_Page/plus.png').default} width={50} className="img-fluid" />}
                                </Col>
                            </Row>
                            <div className={`${active === 2 ? 'content show mt-3 text-uppercase text-gray ff-regular ' : 'content text-uppercase text-gray ff-regular '}`}>
                                You may purchase Memento Mori NFTs using either a credit card or cryptocurrency! The entire purchase process is as easy as making any other purchase online.
                            </div>
                        </div>
                    </div>
                </div>
                <div className='d-flex flex-column flex-md-row w-100'>
                    <div className='w-100'>
                        <div className='border-faq p-3 cursor-pointer' onClick={() => toggle(3)}>
                            <Row className='d-flex align-items-center justify-content-between '>
                                <Col xs="2">
                                    <h2 className='text-gray ff-bold mb-0'>03</h2>
                                </Col>
                                <Col xs="8" className='question-div d-flex align-items-center'>
                                    <h3 className='text-gray ff-bold mb-0 text-uppercase'>Can I buy Memento Mori NFTs with a credit card?</h3>
                                </Col>
                                <Col xs="2" className='text-right'>
                                    {active === 3 ? <img src={require('../../assets/img/Landing_Page/minus.png').default} width={50} className="img-fluid" /> : <img src={require('../../assets/img/Landing_Page/plus.png').default} width={50} className="img-fluid" />}
                                </Col>
                            </Row>
                            <div className={`${active === 3 ? 'content show mt-3 text-uppercase text-gray ff-regular ' : 'content text-uppercase text-gray ff-regular '}`}>
                                Yes, you may purchase Memento Mori NFTs with your credit card! All you need is your email and credit card.
                            </div>
                        </div>
                    </div>
                    <div className='w-100'>
                        <div className='border-faq p-3 cursor-pointer' onClick={() => toggle(4)}>
                            <Row className='d-flex align-items-center justify-content-between '>
                                <Col xs="2">
                                    <h2 className='text-gray ff-bold mb-0'>04</h2>
                                </Col>
                                <Col xs="8" className='question-div d-flex align-items-center'>
                                    <h3 className='text-gray ff-bold mb-0 text-uppercase'>Can I buy Memento Mori NFTs with cryptocurrency?</h3>
                                </Col>
                                <Col xs="2" className='text-right'>
                                    {active === 4 ? <img src={require('../../assets/img/Landing_Page/minus.png').default} width={50} className="img-fluid" /> : <img src={require('../../assets/img/Landing_Page/plus.png').default} width={50} className="img-fluid" />}
                                </Col>
                            </Row>
                            <div className={`${active === 4 ? 'content show mt-3 text-uppercase text-gray ff-regular ' : 'content text-uppercase text-gray ff-regular '}`}>
                                Yes, you may purchase Memento Mori NFTs with cryptocurrency. Memento Mori NFTs will be launched on Ethereum, so in order to purchase with cryptocurrency you need $ETH and a crypto wallet.
                            </div>
                        </div>
                    </div>
                </div>
                <div className='d-flex flex-column flex-md-row w-100'>
                    <div className='w-100'>
                        <div className='border-faq p-3 cursor-pointer' onClick={() => toggle(5)}>
                            <Row className='d-flex align-items-center justify-content-between '>
                                <Col xs="2">
                                    <h2 className='text-gray ff-bold mb-0'>05</h2>
                                </Col>
                                <Col xs="8" className='question-div d-flex align-items-center'>
                                    <h3 className='text-gray ff-bold mb-0 text-uppercase'>Do I need a crypto wallet to buy Memento Mori NFTs?</h3>
                                </Col>
                                <Col xs="2" className='text-right'>
                                    {active === 5 ? <img src={require('../../assets/img/Landing_Page/minus.png').default} width={50} className="img-fluid" /> : <img src={require('../../assets/img/Landing_Page/plus.png').default} width={50} className="img-fluid" />}
                                </Col>
                            </Row>
                            <div className={`${active === 5 ? 'content show mt-3 text-uppercase text-gray ff-regular ' : 'content text-uppercase text-gray ff-regular '}`}>
                                No, you only need an email address to purchase Memento Mori NFTs with your credit card and our provider Winter will store and manage your NFTs for you.
                            </div>
                        </div>
                    </div>
                    <div className='w-100'>
                        <div className='border-faq p-3 cursor-pointer' onClick={() => toggle(6)}>
                            <Row className='d-flex align-items-center justify-content-between '>
                                <Col xs="2">
                                    <h2 className='text-gray ff-bold mb-0'>06</h2>
                                </Col>
                                <Col xs="8" className='question-div d-flex align-items-center'>
                                    <h3 className='text-gray ff-bold mb-0 text-uppercase'>How do I access my NFT?</h3>
                                </Col>
                                <Col xs="2" className='text-right'>
                                    {active === 6 ? <img src={require('../../assets/img/Landing_Page/minus.png').default} width={50} className="img-fluid" /> : <img src={require('../../assets/img/Landing_Page/plus.png').default} width={50} className="img-fluid" />}
                                </Col>
                            </Row>
                            <div className={`${active === 6 ? 'content show mt-3 text-uppercase text-gray ff-regular ' : 'content text-uppercase text-gray ff-regular '}`}>
                                You may view your NFT in your crypto wallet or on Winter where you can easily view, transfer, and use the NFT straight.
                            </div>
                        </div>
                    </div>
                </div>
                <div className='d-flex flex-column flex-md-row w-100'>
                    <div className='w-100'>
                        <div className='border-faq p-3 cursor-pointer' onClick={() => toggle(7)}>
                            <Row className='d-flex align-items-center justify-content-between '>
                                <Col xs="2">
                                    <h2 className='text-gray ff-bold mb-0'>07</h2>
                                </Col>
                                <Col xs="8" className='question-div d-flex align-items-center'>
                                    <h3 className='text-gray ff-bold mb-0 text-uppercase'>How much do the Memento Mori NFTs cost?</h3>
                                </Col>
                                <Col xs="2" className='text-right'>
                                    {active === 7 ? <img src={require('../../assets/img/Landing_Page/minus.png').default} width={50} className="img-fluid" /> : <img src={require('../../assets/img/Landing_Page/plus.png').default} width={50} className="img-fluid" />}
                                </Col>
                            </Row>
                            <div className={`${active === 7 ? 'content show mt-3 text-uppercase text-gray ff-regular ' : 'content text-uppercase text-gray ff-regular '}`}>
                                This will be announced closer to the mint date.
                            </div>
                        </div>
                    </div>
                    <div className='w-100'>
                        <div className='border-faq p-3 cursor-pointer' onClick={() => toggle(8)}>
                            <Row className='d-flex align-items-center justify-content-between '>
                                <Col xs="2">
                                    <h2 className='text-gray ff-bold mb-0'>08</h2>
                                </Col>
                                <Col xs="8" className='question-div d-flex align-items-center'>
                                    <h3 className='text-gray ff-bold mb-0 text-uppercase'>When is the mint date?</h3>
                                </Col>
                                <Col xs="2" className='text-right'>
                                    {active === 8 ? <img src={require('../../assets/img/Landing_Page/minus.png').default} width={50} className="img-fluid" /> : <img src={require('../../assets/img/Landing_Page/plus.png').default} width={50} className="img-fluid" />}
                                </Col>
                            </Row>
                            <div className={`${active === 8 ? 'content show mt-3 text-uppercase text-gray ff-regular ' : 'content text-uppercase text-gray ff-regular '}`}>
                                This will be announced soon.
                            </div>
                        </div>
                    </div>

                </div>
                <div className='d-flex flex-column flex-md-row w-100'>

                    <div className='w-100'>
                        <div className='border-faq p-3 cursor-pointer' onClick={() => toggle(9)}>
                            <Row className='d-flex align-items-center justify-content-between '>
                                <Col xs="2">
                                    <h2 className='text-gray ff-bold mb-0'>09</h2>
                                </Col>
                                <Col xs="8" className='question-div d-flex align-items-center'>
                                    <h3 className='text-gray ff-bold mb-0 text-uppercase'>What is the return policy?</h3>
                                </Col>
                                <Col xs="2" className='text-right'>
                                    {active === 9 ? <img src={require('../../assets/img/Landing_Page/minus.png').default} width={50} className="img-fluid" /> : <img src={require('../../assets/img/Landing_Page/plus.png').default} width={50} className="img-fluid" />}
                                </Col>
                            </Row>
                            <div className={`${active === 9 ? 'content show mt-3 text-uppercase text-gray ff-regular ' : 'content text-uppercase text-gray ff-regular '}`}>
                                All sales of Memento Mori NFTs are final.
                            </div>
                        </div>
                    </div>
                    <div className='w-100'>
                        <div className='border-faq p-3 cursor-pointer' onClick={() => toggle(10)}>
                            <Row className='d-flex align-items-center justify-content-between '>
                                <Col xs="2">
                                    <h2 className='text-gray ff-bold mb-0'>10</h2>
                                </Col>
                                <Col xs="8" className='question-div d-flex align-items-center'>
                                    <h3 className='text-gray ff-bold mb-0 text-uppercase'>What is the shipping policy?</h3>
                                </Col>
                                <Col xs="2" className='text-right'>
                                    {active === 10 ? <img src={require('../../assets/img/Landing_Page/minus.png').default} width={50} className="img-fluid" /> : <img src={require('../../assets/img/Landing_Page/plus.png').default} width={50} className="img-fluid" />}
                                </Col>
                            </Row>
                            <div className={`${active === 10 ? 'content show mt-3 text-uppercase text-gray ff-regular ' : 'content text-uppercase text-gray ff-regular '}`}>
                                All Memento Mori NFTs are delivered electronically. Once you complete your purchase of your NFTs you will receive the necessary link to download your item.
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </section>
    )
}

export default FAQ