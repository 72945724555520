import React from 'react'
import { Col, Row } from 'reactstrap'

const Benefits = () => {
    return (
        <>
            <section className='border-bottom' id="benefits">
                <Row className='d-flex align-items-center justify-content-center'>
                    <Col lg="7">
                        <h2 className='ff-bold text-gray text-uppercase subheading mb-lg-0' data-aos="flip-up">Holder benefits</h2>
                    </Col>
                    <Col lg="5">
                        <div className='d-flex justify-content-lg-end'>
                            <a href="https://twitter.com/" target='_blank'><button className="btn-primary text-gray py-2 px-4 mr-2 ff-semibold">TWITTER</button></a>
                            <a href="https://discord.com/" target='_blank'> <button className="btn-primary text-gray py-2 px-4 ff-semibold">JOIN DISCORD</button></a>
                        </div>
                    </Col>
                </Row>
            </section>
            <section className='border-bottom'>
                <Row className='d-flex align-items-center justify-content-between p-3'>
                    <Col lg="3" className='text-center text-lg-left' data-aos="fade-up"
                        data-aos-duration="3000">
                        <img src={require('../../assets/img/Landing_Page/benefit1.png').default} className="img-fluid" width={300} />
                    </Col>
                    <Col lg="6" className='text-center text-lg-left'>
                        <h1 className='text-uppercase ff-bold text-gray'>Historic Artwork</h1>
                        <p className='text-uppercase ff-regular text-gray font-para'>All holders own a piece of history. This mural is one of the last collaborative works between EL MAC and RETNA. Another piece by EL MAC and RETNA is currently selling for $1M.</p>
                    </Col>
                    <Col lg="3">
                    </Col>
                    <Col lg="1">
                    </Col>
                    <Col lg="3" className='mt-5 mt-lg-0 text-center text-lg-left' data-aos="fade-up"
                        data-aos-duration="3000">
                        <img src={require('../../assets/img/Landing_Page/benefit2.png').default} className="img-fluid" width={300} />
                    </Col>
                    <Col lg="8" className='text-center text-lg-left'>
                        <h1 className='text-uppercase ff-bold text-gray'>High-quality Print</h1>
                        <p className='text-uppercase ff-regular text-gray font-para'>All holders will receive a high-quality physical print of the Memento Mori mural.</p>
                    </Col>
                    <Col lg="2">
                    </Col>
                    <Col lg="3" className='mt-5 mt-lg-0 text-center text-lg-left' data-aos="fade-up"
                        data-aos-duration="3000">
                        <img src={require('../../assets/img/Landing_Page/benefit3.png').default} className="img-fluid" width={300} />
                    </Col>
                    <Col lg="7" className='text-center text-lg-left'>
                        <h1 className='text-uppercase ff-bold text-gray'>Exclusive Music Access</h1>
                        <p className='text-uppercase ff-regular text-gray font-para '>All holders will get full IP rights & commercial access to a few of SYMBA's unreleased tracks.</p>
                    </Col>
                    <Col lg="3">
                    </Col>
                    <Col lg="3" className='mt-5 mt-lg-0 text-center text-lg-left' data-aos="fade-up"
                        data-aos-duration="3000">
                        <img src={require('../../assets/img/Landing_Page/benefit4.png').default} className="img-fluid" width={300} />
                    </Col>
                    <Col lg="6" className='text-center text-lg-left'>
                        <h1 className='text-uppercase ff-bold text-gray'>Studio Tour</h1>
                        <p className='text-uppercase ff-regular text-gray font-para '>1 lucky holder + guest will get flown out to Los Angeles to tour the studio, see the mural in person, and meet SYMBA (&amp; other surprise artists).</p>
                    </Col>
                    <Col lg="4">
                    </Col>
                    <Col lg="3" className='mt-5 mt-lg-0 text-center text-lg-left' data-aos="fade-up"
                        data-aos-duration="3000">
                        <img src={require('../../assets/img/Landing_Page/benefit5.png').default} className="img-fluid" width={300} />
                    </Col>
                    <Col lg="5" className='text-center text-lg-left'>
                        <h1 className='text-uppercase ff-bold text-gray'>SYMBA Meet &amp; Greet</h1>
                        <p className='text-uppercase ff-regular text-gray font-para'>30 lucky holders will get a chance to meet SYMBA in a one-hour Zoom call.</p>
                    </Col>
                </Row>
            </section>
        </>
    )
}

export default Benefits