import Footer from "components/Footer";
import React from "react";
import Artists from "./Artists";
import Banner from "./Banner";
import Benefits from "./Benefits"
import FAQ from "./FAQ";
import Mural from "./Mural";
import NFT from "./NFT";
import Slide from "./Slide";
import Symba from "./Symba";

export default function Index() {
    React.useEffect(() => {
        document.body.classList.toggle("index-page");
        window.scrollTo({ top: 0, behavior: 'smooth' });
        // Specify how to clean up after this effect:
        return function cleanup() {
            document.body.classList.toggle("index-page");
        };

    }, []);
    return (
        <>
            <div className="wrapper" >
                <Banner />
                <Mural />
                <NFT />
                <Slide />
                <Benefits />
                <Symba />
                <Artists />
                <FAQ />
                <Footer />
            </div>
        </>
    );
}