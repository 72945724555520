import { Row, Col, Button, Input } from "reactstrap";
import IndexNavbar from "components/Navbars/IndexNavbar";
import { useState } from "react";
import { ToastContainer } from "react-toastify";
import { openToast } from "helper";

export default function Banner() {

    const [email, setEmail] = useState('')

    const StoreAddress = () => {
        if (email !== '') {
            fetch(`https://www.jackmemento_mori.com/join_jackmemento_mori.php?data=${email}`)
                .then(response => response.json())
                .then(res => {
                    setEmail('')
                    if (res.status === 'success') {
                        openToast("success", "You're on the list! Look out for emails with launch announcements.")
                    }
                })
        }
    }
    return (
        <>
            <div>
                <IndexNavbar className="fixed-top" />
            </div>
            <section>
                <div className="mt-md-5">
                    <div className="banner pt-0" data-aos="zoom-in-up">
                        <Row>
                            <Col lg="6" md="12" sm="12" xs="12" className="text-center text-lg-left">
                                <img src={require('../../assets/img/Landing_Page/banner-image.png').default} className="img-fluid" />
                            </Col>
                            <Col lg="6" md="12" sm="12" xs="12" className="mt-5 mt-lg-0 d-flex flex-column align-items-center justify-content-center justify-content-lg-start align-items-lg-start">
                                <h1 className="ff-bold text-gray border-top">MEMENTO</h1>
                                <h1 className="ff-bold text-gray border-top">MORI</h1>
                            </Col>
                        </Row>
                    </div>
                </div>
            </section>
            <section className="pr-0 banner-text">
                <p className="ff-regular text-gray text-uppercase">CREATED BY THE POPULAR GRAFFITI</p>
                <div className="d-flex align-items-center">
                    <p className="ff-regular text-gray text-uppercase mr-2">ARTISTS </p>
                    <button className="btn-banner py-1">EL MAC &amp; RETNA</button>
                    <img src={require('../../assets/img/Landing_Page/banner-line.png').default} height={1} className="img-fluid" />
                </div>

            </section>
        </>

    )
}